// src/components/EventsSection/EventsSection.tsx

import React from 'react';
import './EventsSection.scss';
import Button from '../Button/Button';

import pizzaImg from '../../assets/pizza.png';
import wingImg from '../../assets/wings.jpeg';

const events = [
  {
    id: 1,
    img: wingImg,
    title: "Wednesday’s Wings",
    description: '20 wings for $20, enjoy hand-cut fresh wings with your choice of sauce.',
  },
  {
    id: 2,
    img: pizzaImg,
    title: "Fridays Personal Pizzas",
    description: '$15 personal deep dish pizza, add up to 3 toppings.',
  },
];

interface EventsSectionProps {
  id?: string; 
}

const EventsSection: React.FC<EventsSectionProps> = ({ id }) => {
  return (
    <section id={id} className="events-section">
      <div className="events-header">
        <span className="dot"></span>
        <h2 className="events-title">Kitchen & Cafe Events</h2>
        <span className="dot"></span>
      </div>
      <div className="events-container">
        {events.map((event) => (
          <div key={event.id} className="event-card">
            <img src={event.img} alt={event.title} className="event-image" />
            <div className="event-info">
              <h3 className="event-title">{event.title}</h3>
              <p className="event-description">{event.description}</p>
              <a href="#reservation-widget" onClick={(e) => { e.preventDefault(); document.getElementById('reservation-widget')?.scrollIntoView({ behavior: 'smooth' }); }}>
                <Button label="Make A Reservation" variant="contained" color="primary" className="event-button" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default EventsSection;