// src/components/Navbar/MobileMenu/MobileMenu.tsx

import React from 'react';
import Button from '../../Button/Button';
import './MobileMenu.scss';

type MobileMenuProps = {
    isOpen: boolean;
    onClose: () => void;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ isOpen, onClose }) => {
    return (
        <div className={`mobile-menu ${isOpen ? 'mobile-menu--open' : 'mobile-menu--closed'}`}>
            <div className="mobile-menu__header">
                <h2>Navigation</h2>
                <button onClick={onClose} className="mobile-menu__close-button">
                    &times;
                </button>
            </div>
            <nav className="mobile-menu__links">
                <a href="#home" onClick={onClose}>Home</a> 
                <a href="#menu" onClick={onClose}>Our Menu</a>  
                <a href="#events" onClick={onClose}>Events</a> 
                <a href="https://allmealprep.com/guest/meal-prep-menu" target="_blank" rel="noopener noreferrer" onClick={onClose}>Order Online</a> 
                <a href="#contact" onClick={onClose}>Contact Us</a> 
            </nav>
            <Button label="Make A Reservation" color="primary" onClick={() => {
                onClose();
                document.getElementById('reservation-widget')?.scrollIntoView({ behavior: 'smooth' });
            }} />
        </div>
    );
};

export default MobileMenu;
