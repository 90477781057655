import React from 'react';
import MUIButton from '@mui/material/Button';
import styles from './Button.module.scss';

interface ButtonProps {
  label: string;
  variant?: 'contained' | 'outlined';
  color?: 'primary' | 'secondary';
  onClick?: () => void;
  style?: React.CSSProperties;
  className?: string; 
}

const Button: React.FC<ButtonProps> = ({ label, variant = 'contained', color = 'primary', onClick, style, className }) => {
  return (
    <MUIButton
      variant={variant}
      color={color}
      onClick={onClick}
      style={style}
      className={`${styles.button} ${styles[color]} ${styles[variant]} ${className}`}
    >
      {label}
    </MUIButton>
  );
};

export default Button;
