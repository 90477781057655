import React from 'react';
import './ImageGallerySection.scss';
import img1 from '../../../assets/img1.png';
import img2 from '../../../assets/img2.png';
import img3 from '../../../assets/img3.png';
import img4 from '../../../assets/img4.png';
import img5 from '../../../assets/img5.png';

const images = [img1, img2, img3, img4, img5];

const ImageGallerySection: React.FC = () => {
  return (
    <section className="image-gallery-section">
      <div className="background-strip"></div>
      <div className="gallery-container">
        {images.map((src, index) => (
          <div key={index} className="gallery-image">
            <img src={src} alt={`Gallery Image ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default ImageGallerySection;
