// src/components/HeroSection/HeroSection.tsx

import React from 'react';
import Button from '../Button/Button';
import './HeroSection.scss';
import ImageGallerySection from './ImageGallerySection/ImageGallerySection';

interface HeroSectionProps {
  id?: string;
}

const HeroSection: React.FC<HeroSectionProps> = ({ id }) => {
  return (
    <section className="hero-section" id={id}>
      <h1 className="hero-title">All Meal Prep</h1>
      <h2 className="hero-subtitle">Kitchen & Cafe</h2>
      <p className="hero-description">
        Experience All Meal Prep’s First Dine-In Restaurant And Cafe For A Fun Twist Of Flavors Of Our Signature Dishes!
      </p>
      <div className="hero-buttons">
        <a href="#reservation-widget" onClick={(e) => { e.preventDefault(); document.getElementById('reservation-widget')?.scrollIntoView({ behavior: 'smooth' }); }}>
          <Button label="Make A Reservation" variant="contained" color="primary" />
        </a>
        <a href="#menu">
          <Button label="View Our Menu" variant="outlined" color="primary" />
        </a>
      </div>
      <ImageGallerySection />
    </section>
  );
};

export default HeroSection;
