import React from 'react';
import './MenuSection.scss';
import menu1 from '../../assets/menu1.png';
import menu2 from '../../assets/menu2.png';
import menu3 from '../../assets/menu3.png';
import menu4 from '../../assets/menu4.png';
import menu5 from '../../assets/menu5.png';
import menu6 from '../../assets/menu6.png';
import Button from '../Button/Button';

const menuImages = [menu1, menu2, menu3, menu4, menu5, menu6];

interface MenuSectionProps {
  id?: string; 
}

const MenuSection: React.FC<MenuSectionProps> = ({ id }) => {
  const handleImageClick = (src: string) => {
    window.open(src, '_self');
  };

  return (
    <section className="menu-section" id={id}> 
      <div className="menu-header">
        <span className="dot"></span>
        <h2 className="menu-title">Our Menu</h2>
        <span className="dot"></span>
      </div>
      <p className="menu-description">
        Explore a variety of healthy meals, highlighting diverse flavors and global cuisines!
      </p>
      <div className="menu-images">
        {menuImages.slice(0, 4).map((src, index) => (
          <img 
            key={index} 
            src={src} 
            alt={`Menu ${index + 1}`} 
            className="menu-image" 
            onClick={() => handleImageClick(src)} 
          />
        ))}
      </div>
      <div className="menu-images-bottom">
        {menuImages.slice(4).map((src, index) => (
          <img 
            key={index + 4} 
            src={src} 
            alt={`Menu ${index + 5}`} 
            className="menu-image" 
            onClick={() => handleImageClick(src)} 
          />
        ))}
      </div>
      <div className="menu-button">
        <Button label="Make A Reservation" variant="contained" color="primary" onClick={() => document.getElementById('reservation-widget')?.scrollIntoView({ behavior: 'smooth' })} />
      </div>
    </section>
  );
};

export default MenuSection;