import React from 'react';
import TextField from '@mui/material/TextField';

interface InputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  height?: string;
  type?: string;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  placeholderColor?: string; 
}

const Input: React.FC<InputProps> = ({ 
  label, 
  value, 
  onChange, 
  width = '100%', 
  height = '50px', 
  type = 'text', 
  placeholder,
  multiline = false,
  rows = 1,
  placeholderColor = '#FFFFFF' 
}) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      variant="outlined"
      multiline={multiline}
      rows={rows}
      InputLabelProps={{
        shrink: true,
        style: { color: '#FFFFFF' },
      }}
      InputProps={{
        style: { 
          color: '#FFFFFF',
          borderRadius: '8px',
          height,
        },
      }}
      sx={{
        width,
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#FFFFFF',
          },
          '&:hover fieldset': {
            borderColor: '#FFFFFF',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#FFFFFF',
          },
          input: {
            color: '#FFFFFF',
            '::placeholder': {
              color: placeholderColor, 
              opacity: 1,
            },
          },
        },
      }}
    />
  );
};

export default Input;
