// src/styles/theme.ts

import { createTheme } from '@mui/material/styles';

// Kreiraj temu koristeći prilagođene boje iz variables.scss
const theme = createTheme({
  palette: {
    primary: {
      main: '#FF6A00',  // use primary-color
    },
    secondary: {
      main: '#000000',  // use black-color
    },
    background: {
      default: '#FFFFFF',  // use white-color
    },
    text: {
      primary: '#262626',  // use dark-gray-color
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif', // use primarni font
    h1: { fontSize: '2.5rem', fontWeight: 600 },
    h2: { fontSize: '2rem', fontWeight: 600 },
    body1: { fontSize: '1rem' },
  },
});

export default theme;
