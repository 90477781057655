import React from 'react';
import logo from '../../../assets/logo.png'; 

const Logo: React.FC = () => {
    return (
        <div className="logo">
            <img src={logo} alt="All Meal Prep Logo" />
        </div>
    );
};

export default Logo;
