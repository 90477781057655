import React, { useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import HeroSection from './components/HeroSection/HeroSection';
import MenuSection from './components/MenuSection/MenuSection';
import WeeklyMealPrepsSection from './components/WeeklyMealPrepsSection/WeeklyMealPrepsSection';
import EventsSection from './components/EventsSection/EventsSection';
import ContactSection from './components/ContactSection/ContactSection';
import Footer from './components/Footer/Footer';

let isScriptLoaded = false; // Global flag to prevent multiple script insertions

const App: React.FC = () => {
  useEffect(() => {
    if (!isScriptLoaded) {
      const script = document.createElement('script');
      script.src = "//www.opentable.com/widget/reservation/loader?rid=1351144&type=standard&theme=standard&color=1&dark=false&iframe=true&domain=com&lang=en-US&newtab=false&ot_source=Restaurant%20website&cfe=true";
      script.async = true;
      script.id = "opentable-script";
      document.getElementById('reservation-widget')?.appendChild(script);
  
      script.onload = () => {
        const iframe = document.querySelector('#reservation-widget iframe') as HTMLIFrameElement;
        if (iframe) {
          iframe.classList.add('centered-widget');
        }
      };
  
      isScriptLoaded = true; // Set the flag to true after script is added
    }
  }, []);

  return (
    <div>
      <Navbar />
      <HeroSection id="home" /> 
      <MenuSection id="menu" />
      <div className='res-widget' id="reservation-widget" style={{ marginTop: '20px',textAlign:'center' }}>
        {/* OpenTable widget will load here */}
      </div>   
      <WeeklyMealPrepsSection />
      <EventsSection id="events" /> 
      <ContactSection id="contact" />      
      <Footer />

    </div>
  );
};

export default App;
