// src/components/Navbar/NavLinks/NavLinks.tsx

import React from 'react';
import './NavLinks.scss';

const NavLinks: React.FC = () => {
    return (
        <ul className="nav-links">
            <li><a href="#home" className="home-link">Home</a></li>
            <li><a href="#menu">Menu</a></li>    
            <li><a href="#contact">Contact Us</a></li> 
        </ul>
    );
};

export default NavLinks;
