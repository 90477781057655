// src/components/WeeklyMealPrepsSection/WeeklyMealPrepsSection.tsx

import React from 'react';
import Button from '../Button/Button';
import './WeeklyMealPrepsSection.scss';

const WeeklyMealPrepsSection: React.FC = () => {
  return (
    <section className="weekly-meal-preps-section">
      <div className="overlay">
        <div className="content">
          <h2 className="section-title">Order Weekly Meal Preps</h2>
          <p className="section-description">
            Try Our Delicious Meals In House And Order Them For The Week. With Our Online Meal Prep Service, You
            Can Have Quality, Nutritious Meals Right At Your Doorstep. Our Diverse Menu Caters To All Dietary
            Preferences, Ensuring That There's Something For Everyone.
          </p>
          <p className="section-description">
            With A User-Friendly Interface, You Can Easily Customize Your Meals And Schedule Deliveries At Your
            Convenience. Enjoy Fresh, Chef-Prepared Dishes With Just A Few Clicks!
          </p>
          <a 
            href="https://allmealprep.com/guest/meal-prep-menu" 
            target="_blank" 
            rel="noopener noreferrer"
          >
            <Button label="Order Meal Preps Online" variant="contained" color="primary" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default WeeklyMealPrepsSection;
